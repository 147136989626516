import { tokenResult } from "@/Study/helpers";

export const getQuestionnaireList = () => allQuestionnaires;

export const getRecordParams = () => ({});

export const getRouteFromData = (data: tokenResult) => !data.usedAt ? 'demographics' : 'final';

export const allQuestionnaires: 'vaccine'[] = ['vaccine'];

export const getInviteMessage = (link: string) => {
    return `זה הקישור לשאלון שלך. תודה על השתתפותך 🦋\n\r${link}\n\rנתקלת בבעיות טכניות במהלך מילוי השאלון? ניתן לפנות אלינו לעזרה.\nשימו לב: הקישור הוא אישי לכל נבדק.`
}